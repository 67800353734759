const hero = () => {
  const hero = document.querySelector(".hero");
  if (!hero) return;
  const heroCta = hero.querySelector(".cta");
  // const scrollConstant = document.querySelector(".scroll-constant");
  const heroContent = document.querySelector(".hero__content");
  // const navLogo = document.querySelector(".nav__logo-wrap");

  window.addEventListener("scroll", function (e) {
    // let positionInUsableVal = scrollConstant.getBoundingClientRect().y * -1;
    var scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    console.log(heroCta.clientHeight);

    heroCta.style.opacity = `${1 - scrollTop / hero.clientHeight}`;
    heroContent.style.top = `${-scrollTop / 4}px`;
  });
};

// $(window).scroll(function(){
//     $("#hero-image").css("opacity", 1 - $(window).scrollTop() / ($('#hero-image').height() / 2));
// });

export default hero;
