const scrollToSection = () => {
  // check for button/link with class of scroll-to
  // get data attr of 'data-target'
  // scroll to target section with the same id 'data-target'
  // the old way to do this gets the window position and the
  // clientBounding of the new element position,
  // but now we can use scrollIntoView
  const allLinkItems = document.querySelectorAll(".scroll-to");
  if (!allLinkItems[0]) return;

  allLinkItems.forEach((element) => {
    element.addEventListener("click", function () {
      const target = document.querySelector(
        `#${this.getAttribute("data-target")}`
      );

      // set window new position @ the target item
      target.scrollIntoView({
        behavior: "smooth",
      });
    });
  });
};

export default scrollToSection;
