import ScrollReveal from "scrollreveal";

const scrollRevealInit = () => {
  const allRevealItems = document.querySelectorAll(".s-show");
  if (!allRevealItems) return;

  ScrollReveal().reveal(allRevealItems, {
    easing: "ease-in",
    distance: "20%",
    duration: 400,
    origin: "bottom",
    delay: 100,
    opacity: 0.1,
    interval: 100,
  });
};

export default scrollRevealInit;
