const revealSections = () => {
  const allSections = document.querySelectorAll(".scroll-reveal");
  if (!allSections[0]) return;

  const revealSection = function (entries, observer) {
    const [entry] = entries;

    if (!entry.isIntersecting) return;
    if (entry.target.classList.contains("accordion-section")) {
      entry.target.classList.add("reveal-section--revealed");
    } else {
      entry.target.classList.add("scroll-reveal--revealed");
    }
  };

  const sectionObserver = new IntersectionObserver(revealSection, {
    root: null,
    threshold: 0.4,
  });

  allSections.forEach(function (section) {
    sectionObserver.observe(section);
  });
};

export default revealSections;
