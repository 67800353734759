const heroFlr = () => {
  // change the left, top and rotation randomly based on peramiters
  const hero = document.querySelector(".cta__inner-flr");
  if (!hero) return;

  const poly1 = document.querySelector(".cta__poly--1");
  const poly2 = document.querySelector(".cta__poly--2");
  const poly3 = document.querySelector(".cta__poly--3");
  // get a random number function between 0 and the number passed in
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const pollyTimeOutTop = function (
    target,
    thresholdTop,
    thresholdNormalPoint,
    thresholdRight,
    thresholdRightNormalPoint
  ) {
    setTimeout(() => {
      target.style.top = `${
        getRandomInt(thresholdTop) + thresholdNormalPoint
      }%`;
      target.style.right = `${getRandomInt(
        thresholdRight + thresholdRightNormalPoint
      )}%`;

      target.style.transform = `rotate(${getRandomInt(180)}deg)`;

      pollyTimeOutTop(
        target,
        thresholdTop,
        thresholdNormalPoint,
        thresholdRight,
        thresholdRightNormalPoint
      );
    }, "1200");
  };

  pollyTimeOutTop(poly1, 4, 4, 7, 0);
  pollyTimeOutTop(poly2, 4, 28, 6, 30);
  pollyTimeOutTop(poly3, 5, 80, 5, 5);
};

export default heroFlr;
