// TODO:
// add reveal-section--revealed when 'reveal-section' is in viewport on these items
// add animation to cta flair
// fade out hero cta on sscroll down
// add accordion toggle classes
import test from "/src/js/modules/test";
import accordion from "/src/js/modules/accordion";
import tabs from "/src/js/modules/tabs";
import formInput from "/src/js/modules/form-input";
import revealSections from "/src/js/modules/scroll-reveal";
import hero from "/src/js/modules/hero";
import lottieTest from "/src/js/modules/lottie-test";
import scrollToSection from "/src/js/modules/smooth-scroll";
import scrollRevealInit from "/src/js/modules/scroll-reveal-vendor";
// import navLogo from "/src/js/modules/nav-logo";
// import contactForm from "/src/js/modules/contact-form.js";
import heroFlr from "/src/js/modules/hero-flr";
// Check dom ready:
const domReady = (callBack) => {
  document.addEventListener("DOMContentLoaded", callBack);
  if (
    // document.readyState === "interactive" ||
    document.readyState === "complete"
  ) {
    callBack();
  }
};

const WhenDomLoaded = () => {
  console.log("Dom Loaded!");
  // test();
  accordion();
  tabs();
  formInput();
  revealSections();
  hero();
  lottieTest();
  scrollToSection();
  heroFlr();
  // navLogo();
  // contactForm();
  scrollRevealInit();
};

domReady(() => WhenDomLoaded());
