const accordion = () => {
  const allInstance = document.querySelectorAll(".accordion");

  if (!allInstance[0]) return;

  const toggleOpenItem = function (btnTarget) {
    const target = btnTarget.closest(".accordion__item");
    target.classList.toggle("accordion__item--open");
  };

  for (let i = 0; i < allInstance.length; i++) {
    const accordionElement = allInstance[i];
    // console.log(accordionElement);
    // capture the click event on the accordion section
    // wait until the click is the button element
    // if the button element is clicked, then toggle the class
    // 'accordion__item--open' on the cloeset 'accordion__item'.
    accordionElement.addEventListener("click", function (e) {
      // console.log(this, e.target);
      if (e.target.classList.contains("accordion__btn")) {
        toggleOpenItem(e.target);
      }
    });
  }
};

export default accordion;
