// import LottieInteractivity from "/node_modules/@lottiefiles/lottie-interactivity";
import { create } from "@lottiefiles/lottie-interactivity";

const lottieTest = () => {
  const fillUp = document.querySelector("#fillup");
  const mail = document.querySelector("#mail");
  if (fillUp) {
    create({
      mode: "scroll",
      player: "#fillup",
      actions: [
        {
          visibility: [0, 0.4],
          type: "stop",
          frames: [0],
        },
        {
          visibility: [0.4, 1],
          type: "seek",
          frames: [0, 100],
        },
      ],
    });
  }
  if (mail) {
    // mail
    create({
      mode: "scroll",
      player: "#mail",
      actions: [
        {
          visibility: [0, 0.2],
          type: "stop",
          frames: [0],
        },
        {
          visibility: [0.2, 1],
          type: "seek",
          frames: [0, 100],
        },
      ],
    });
  }
};

export default lottieTest;
