const formInput = () => {
  const allInputTarget = document.querySelectorAll(".input-target");

  if (!allInputTarget[0]) return;

  const addVisitedActive = function (target) {
    const inputGroup = target.closest(".form__input-group");
    inputGroup.classList.add("is-active");
  };

  for (let i = 0; i < allInputTarget.length; i++) {
    const formElement = allInputTarget[i];
    formElement.addEventListener("focus", function (e) {
      if (
        e.target.classList.contains("input-target") &&
        e.target.matches(":focus")
      ) {
        console.log("input");
        addVisitedActive(e.target);
      }
    });
  }
};

export default formInput;
