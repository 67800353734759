// show / hide tabs by toggling is-active class on the button's parent
// and used the matching data attr to target and toggle is-active on the
// matching content area.

const tabs = () => {
  const allInstance = document.querySelectorAll(".tab-block");
  if (!allInstance[0]) return;

  const clearTabs = function (btnTarget) {
    // clear is-active from all items.
    const blockParent = btnTarget.closest(".tab-block__content");
    // all 'is-active' items
    const allActive = blockParent.querySelectorAll(".is-active");
    for (let i = 0; i < allActive.length; i++) {
      const activeElement = allActive[i];
      activeElement.classList.remove("is-active");
    }

    showTab(btnTarget);
  };

  const changeBackground = function (btnTarget, dataLoopIndex) {
    const selectedTerm = "tab-block__bk--selected";
    const backgroundTarget = btnTarget
      .closest(".tab-block")
      .querySelector(`[data-block="${dataLoopIndex}"]`);

    const allBackgroundItems = btnTarget
      .closest(".tab-block")
      .querySelectorAll(".tab-block__bk");

    const removeAll = allBackgroundItems.forEach((element) => {
      element.classList.remove(selectedTerm);
    });
    setTimeout(removeAll, 800);

    backgroundTarget.classList.add(selectedTerm);
  };

  const showTab = function (btnTarget) {
    const parent = btnTarget.closest("li");
    const dataLoopIndex = parent.getAttribute("data-tab") + "";
    const blockParent = btnTarget.closest(".tab-block__content");

    // find parent tab-block__content
    // unhide element with data-attr matching dataLoopIndex
    const target = blockParent.querySelector(
      `[data-content="${dataLoopIndex}"]`
    );
    target.classList.add("is-active");
    // add active to button parent
    parent.classList.add("is-active");
    // change background to match data-block #
    changeBackground(btnTarget, dataLoopIndex);
  };

  for (let i = 0; i < allInstance.length; i++) {
    const tabsElement = allInstance[i];
    tabsElement.addEventListener("click", function (e) {
      if (
        e.target.classList.contains("tab-block__btn") &&
        !e.target.classList.contains("is-active")
      ) {
        clearTabs(e.target);
      }
    });
  }
};

export default tabs;
